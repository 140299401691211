import { GetFlightsResponseV1 } from '@backend/responses';
import { WebError } from '@backend/types';
import { backendApi } from 'api/backendApi';
import { Button } from 'components/Button';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as S from './FlightDetail.styles';

type FlightDetailProps = {
  flightId: number;
};

export const FlightDetail = ({ flightId }: FlightDetailProps) => {
  const [flight, setFlight] = useState<GetFlightsResponseV1>();
  const navigate = useNavigate();

  const getFlight = async () => {
    const response = await backendApi.get<GetFlightsResponseV1 | WebError>(
      `/api/v1/flight/${flightId}`,
    );
    if (response.status !== 200) {
      toast.error('Failed to fetch flight data');
      return;
    }

    setFlight(response.data as GetFlightsResponseV1);
  };

  const handleAssignFlight = async () => {
    const response = await backendApi.post(`/api/v1/flight/${flightId}/assign`);
    if (response.status !== 200) {
      toast.error('Unable to assign flight');
      return;
    }

    toast.success('Flight successfully assigned to you!');
    navigate('/current-flight');
  };

  useEffect(() => {
    getFlight();
  }, []);

  if (!flight) {
    return null;
  }

  return (
    <S.Wrapper>
      <S.FlightDetails>
        <S.FlightDetail>Status: {flight.status}</S.FlightDetail>
        <S.FlightDetail>Departure: {flight.departure.icao}</S.FlightDetail>
        <S.FlightDetail>Destination: {flight.destination.icao}</S.FlightDetail>
        <S.FlightDetail>
          Aircraft: {flight.aircraft.register} (
          {flight.aircraft.aircraftModel.typeDesignator})
        </S.FlightDetail>
        <S.FlightDetail>Payment: ${flight.payment}</S.FlightDetail>
      </S.FlightDetails>

      {flight.status === 'Available' && (
        <Button onClick={handleAssignFlight}>Assign</Button>
      )}
    </S.Wrapper>
  );
};
