import { AppContainer } from 'components/AppContainer';
import { BuyAircraftTable } from 'components/BuyAircraftTable';

export const BuyAircraftListPage = () => {
  return (
    <AppContainer title="Buy Aircraft">
      <BuyAircraftTable />
    </AppContainer>
  );
};
