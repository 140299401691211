import styled, { css } from 'styled-components';

export const Table = styled.table``;

export const TableHeaderRow = styled.tr`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    background-color: ${theme.colors.primary};
  `}
`;

export const TableHeader = styled.th`
  ${({ theme }) => css`
    padding: ${theme.spacings.xsmall} 0;
    width: 30rem;
  `}
`;

type TableRowProps = {
  clickable: boolean;
};

export const TableRow = styled.tr<TableRowProps>`
  ${({ theme, clickable }) => css`
    cursor: ${clickable ? 'pointer' : ''};
    :hover {
      background-color: ${clickable ? theme.colors.gray : 'inherit'};
    }
  `}
`;

export const TableValue = styled.td`
  ${({ theme }) => css`
    width: 30rem;
    padding: ${theme.spacings.xxsmall} 0;
    text-align: center;
  `}
`;
