import { GetAircraftModelResponseV1 } from '@backend/responses';
import { buyAircraftApi, getAircraftModel } from 'api/backendApi';
import { Button } from 'components/Button';
import { Detail } from 'components/Detail';
import * as FormStyles from 'components/Form';
import { TextInput } from 'components/TextInput';
import { Title } from 'components/Title';
import { userStore } from 'contexts/userContext';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useStore } from 'zustand';
import * as S from './BuyAircraftTable.styles';

type BuyAircraftDetailsProps = {
  aircraftModelId: number;
};

export const BuyAircraftDetail = ({
  aircraftModelId,
}: BuyAircraftDetailsProps) => {
  const [aircraftModel, setAircraftModel] =
    useState<GetAircraftModelResponseV1>();
  const [register, setRegister] = useState('');
  const [airport, setAirport] = useState('');
  const navigate = useNavigate();
  const { updateUser } = useStore(userStore);

  const loadAircraft = async () => {
    const result = await getAircraftModel(aircraftModelId);
    if (!result.isOk) {
      toast.error('Failed to fetch aircraft model');
      return;
    }

    setAircraftModel(result.data);
  };

  useEffect(() => {
    loadAircraft();
  }, []);

  const handleBuyAircraft: React.FormEventHandler<HTMLFormElement> = async (
    e,
  ) => {
    e.preventDefault();

    const result = await buyAircraftApi({
      aircraftModelId,
      register,
      airport,
    });

    if (!result.isOk) {
      toast.error('Unable to buy aircraft. ' + result.webError.message);
      return;
    }

    updateUser();
    toast.success('Aircraft purchased successfully!');
    navigate('/my-aircraft');
  };

  if (!aircraftModel) {
    return null;
  }

  return (
    <S.Wrapper>
      <Detail label="Aircraft Model" content={aircraftModel.name} />
      <Detail
        label="Fuel Capacity"
        content={aircraftModel.maxFuelInUSGallons.toLocaleString()}
      />
      <Detail label="Price" content={aircraftModel.price} />

      <S.FormWrapper>
        <FormStyles.Form onSubmit={handleBuyAircraft}>
          <Title>Buy your aircraft!</Title>
          <TextInput
            name="airport"
            label="Airport"
            onInputChange={setAirport}
          />
          <TextInput
            name="register"
            label="Register"
            onInputChange={setRegister}
          />
          <Button>Buy {aircraftModel.name}</Button>
        </FormStyles.Form>
      </S.FormWrapper>
    </S.Wrapper>
  );
};
