import { CurrentFlightV1Response } from '@backend/domain/user/routes/v1/handlers/current-flight';
import { backendApi } from 'api/backendApi';
import { Button } from 'components/Button';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as S from './CurrentFlight.styles';

export const CurrentFlight = () => {
  const navigate = useNavigate();
  const [flight, setFlight] = useState<CurrentFlightV1Response>();

  const loadFlight = async () => {
    const response = await backendApi.get<CurrentFlightV1Response>(
      '/api/v1/user/current-flight',
    );

    if (response.status === 404) {
      toast.warning('No active flight!');
      navigate('/available-flights');
      return;
    }

    if (response.status !== 200) {
      return;
    }

    setFlight(response.data);
  };

  useEffect(() => {
    loadFlight();
  }, []);

  if (!flight) {
    return null;
  }

  const handleCancelFlight = async () => {
    const response = await backendApi.post(
      `/api/v1/flight/${flight.id}/cancel`,
    );
    if (response.status !== 200) {
      toast.error('Unable to cancel flight');
      return;
    }

    toast.success('Flight successfully canceled!');
    navigate('/available-flights');
  };

  return (
    <div>
      <S.FlightDetails>
        <S.FlightDetail>Status: {flight.status}</S.FlightDetail>
        <S.FlightDetail>Departure: {flight.departure}</S.FlightDetail>
        <S.FlightDetail>Destination: {flight.destination}</S.FlightDetail>
        <S.FlightDetail>
          Aircraft: {flight.aircraftRegister} ({flight.aircraftModel})
        </S.FlightDetail>
        {/* <S.FlightDetail>Passengers: {flight.passengers}</S.FlightDetail>
        <S.FlightDetail>
          Payload: {flight.payloadWeightInPounds} lb
        </S.FlightDetail> */}
        <S.FlightDetail>Payment: ${flight.payment}</S.FlightDetail>
      </S.FlightDetails>
      {['Flying', 'Assigned'].includes(flight.status) && (
        <Button onClick={handleCancelFlight}>Cancel</Button>
      )}
    </div>
  );
};
