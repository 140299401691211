import { GetAircraftModelResponseV1 } from '@backend/responses';
import { getAircraftModelListApi } from 'api/backendApi';
import { PaginatedTable } from 'components/PaginatedTable';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const tableHeader = {
  id: 'Id',
  name: 'Name',
  typeDesignator: 'Type designator',
  price: 'Price',
};

const formatAircraftModelsToTable = ({
  id,
  name,
  typeDesignator,
  price,
}: GetAircraftModelResponseV1) => {
  return { id, name, typeDesignator, price };
};

export const BuyAircraftTable = () => {
  const [aircraftModels, setAircraftModels] =
    useState<GetAircraftModelResponseV1[]>();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();

  const loadAircraftModels = async () => {
    const result = await getAircraftModelListApi(page);
    if (!result.isOk) {
      toast.error('Failed to fetch aircraft models');
      return;
    }

    setAircraftModels(result.data.items);
    setTotalPages(result.data._meta.totalPages);
  };

  useEffect(() => {
    loadAircraftModels();
  }, [page]);

  if (!aircraftModels) {
    return null;
  }

  const handleRowClick = (aircraftModelId: number | string) => {
    navigate(`/buy-aircraft/${aircraftModelId}`);
  };

  return (
    <PaginatedTable
      page={page}
      totalPages={totalPages}
      headers={tableHeader}
      onNext={() => setPage((v) => v + 1)}
      onPrev={() => setPage((v) => v - 1)}
      itemId="id"
      onRowClick={handleRowClick}
      items={aircraftModels.map(formatAircraftModelsToTable)}
    />
  );
};
