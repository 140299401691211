import styled, { css } from 'styled-components';

export const Wrapper = styled.div``;

export const FlightDetails = styled.ul`
  ${({ theme }) => css`
    margin: ${theme.spacings.medium} 0;
  `}
`;

export const FlightDetail = styled.li`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.large};
    margin: ${theme.spacings.xsmall} 0;
  `}
`;
