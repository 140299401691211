import styled, { css } from 'styled-components';
import * as DetailStyles from 'components/Detail';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    padding: 0 ${theme.spacings.xxlarge};
  `}
`;

export const AircraftDetails = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin: ${theme.spacings.medium} 0;

    ${DetailStyles.Wrapper} {
      margin: ${theme.spacings.xxsmall} 0;
    }
  `}
`;

export const RefuelWrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacings.medium};
    box-shadow: ${theme.boxShadow.large};
    border-radius: ${theme.border.radius};
  `}
`;

export const CreateFlightWrapper = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.large};
    padding: ${theme.spacings.medium};
    box-shadow: ${theme.boxShadow.large};
    border-radius: ${theme.border.radius};
  `}
`;

export const HeaderInformation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export const HeaderAircraft = styled.div`
  width: 70rem;
`;

export const HeaderActions = styled.div`
  width: 60rem;
`;

export const PayloadAvailableWrapper = styled.div`
  ${({ theme }) => css`
    width: 120rem;
    margin-top: ${theme.spacings.large};
    margin-bottom: ${theme.spacings.xxlarge};
    margin-left: auto;
    margin-right: auto;
  `}
`;
