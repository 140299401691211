import { AircraftDetail } from 'components/AircraftDetail';
import { AppContainer } from 'components/AppContainer';
import { useNavigate, useParams } from 'react-router-dom';

export const AircraftDetailPage = () => {
  const { aircraftId } = useParams();
  const navigate = useNavigate();

  if (!aircraftId) {
    navigate('/my-aircraft');
    return null;
  }

  return (
    <AppContainer>
      <AircraftDetail aircraftId={aircraftId} />
    </AppContainer>
  );
};
