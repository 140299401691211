import styled, { css } from 'styled-components';

export const Wrapper = styled.nav`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: ${theme.spacings.medium} ${theme.spacings.xxlarge};
  `}
`;
export const NavItems = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
`;

export const NavItem = styled.li`
  cursor: pointer;
  font-size: ${({ theme }) => theme.font.sizes.large};
  margin-right: ${({ theme }) => theme.spacings.medium};
  :hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const NavItemWithoutClick = styled.li`
  font-size: ${({ theme }) => theme.font.sizes.large};
  margin-right: ${({ theme }) => theme.spacings.medium};
`;
