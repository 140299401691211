import { GetPayloadResponseV1 } from '@backend/responses';
import { getPayloadApi } from 'api/backendApi';
import { PaginatedTable } from 'components/PaginatedTable';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

const payloadTableHeader = {
  id: 'Id',
  isPassenger: 'Is Passenger',
  weightInPounds: 'Weight',
  payment: 'Payment',
};

type PayloadTableProps = {
  airport: string;
};

export const PayloadTable = ({ airport }: PayloadTableProps) => {
  const [payloads, setPayloads] = useState<GetPayloadResponseV1[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const fetchPayloads = async () => {
    const response = await getPayloadApi({
      departure: airport,
      status: 'Available',
      page,
      pageSize: 10,
    });

    if (!response.isOk) {
      toast.error("Unable to fetch airport's payload");
      return;
    }

    setPayloads(response.data.items);
    setTotalPages(response.data._meta.totalPages);
  };

  useEffect(() => {
    fetchPayloads();
  }, []);

  const formatPayload = (payload: GetPayloadResponseV1) => {
    return {
      id: payload.id,
      isPassenger: payload.isPassenger ? '✔' : 'X',
      weightInPounds: `${payload.weightInPounds} lb`,
      payment: `$${payload.payment}`,
    };
  };

  return (
    <PaginatedTable
      page={page}
      totalPages={totalPages}
      onNext={() => setPage((v) => v + 1)}
      onPrev={() => setPage((v) => v - 1)}
      items={payloads.map(formatPayload)}
      itemId="id"
      headers={payloadTableHeader}
    />
  );
};
