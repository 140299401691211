import * as S from './Detail.styles';

type DetailProps = {
  label: string;
  content: string | number;
};

export const Detail = ({ label, content }: DetailProps) => {
  return (
    <S.Wrapper>
      <S.Label>{label}:</S.Label> {content}
    </S.Wrapper>
  );
};
