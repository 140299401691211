import { airportSchema } from '@backend/responses';
import { getAirportByIdApi } from 'api/backendApi';
import { AppContainer } from 'components/AppContainer';
import { Detail } from 'components/Detail';
import { PayloadTable } from 'components/PayloadTable';
import { Title } from 'components/Title';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { z } from 'zod';

export const AirportDetail = () => {
  const [airport, setAirport] = useState<z.infer<typeof airportSchema>>();
  const { airportId } = useParams();

  const fetchAirport = async () => {
    if (!airportId) {
      return;
    }

    const result = await getAirportByIdApi(airportId);
    if (!result.isOk) {
      toast.error('Unable to fetch airport');
      return;
    }

    setAirport(result.data);
  };

  useEffect(() => {
    fetchAirport();
  }, [airportId]);

  if (!airport) {
    return null;
  }

  return (
    <AppContainer title={`Airport ${airport.icao}`}>
      <Detail label="Fuel Price" content={`$${airport.fuelPricePerUSGallon}`} />
      <br />
      <br />
      <br />
      <Title>Available payload</Title>
      <PayloadTable airport={airport.icao} />
    </AppContainer>
  );
};
