import { backendApi } from 'api/backendApi';
import { GetFlightsResponseV1, PaginatedResponse } from '@backend/responses';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PaginatedTable } from 'components/PaginatedTable';

const headers = {
  id: 'Id',
  status: 'Status',
  departure: 'Departure',
  destination: 'Destination',
  payload: 'Payload',
  passengers: 'Passengers',
  payment: 'Payment',
};

export const AvailableFlightsTable = () => {
  const navigate = useNavigate();
  const [flights, setFlights] = useState<GetFlightsResponseV1[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);

  const loadFlights = async () => {
    const response = await backendApi.get<
      PaginatedResponse<GetFlightsResponseV1>
    >(`/api/v1/flight?status=Available&page=${page}`);
    if (response.status !== 200) {
      return;
    }

    setTotalPages(response.data._meta.totalPages);
    setFlights(response.data.items);
  };

  useEffect(() => {
    loadFlights();
  }, [page]);

  const handleRowClick = (itemId: number | string) => {
    navigate(`/flight/${itemId}`);
  };

  const formatFlightsToTable = ({
    id,
    status,
    departure,
    destination,
    payment,
    payloadWeightInPounds,
    passengers,
  }: GetFlightsResponseV1) => {
    return {
      id,
      status,
      departure: departure.icao,
      destination: destination.icao,
      payload: `${payloadWeightInPounds} lb`,
      passengers,
      payment: `$${payment} `,
    };
  };

  return (
    <PaginatedTable
      page={page}
      totalPages={totalPages}
      onRowClick={handleRowClick}
      onNext={() => setPage((p) => p + 1)}
      onPrev={() => setPage((p) => p - 1)}
      itemId="id"
      headers={headers}
      items={flights.map(formatFlightsToTable)}
    />
  );
};
