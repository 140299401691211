import { AppContainer } from 'components/AppContainer';
import { CurrentFlight } from 'components/CurrentFlight';

export const CurrentFlightPage = () => {
  return (
    <AppContainer title="Current Flight">
      <CurrentFlight />
    </AppContainer>
  );
};
