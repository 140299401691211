import { AppContainer } from 'components/AppContainer';
import { AvailableFlightsTable } from 'components/AvailableFlightsTable';

export const AvailableFlightsPage = () => {
  return (
    <AppContainer title="Available Flights">
      <AvailableFlightsTable />
    </AppContainer>
  );
};
