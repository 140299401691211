import { ChangeEventHandler, useState } from 'react';
import * as S from './TextInput.styles';

type TextInputProps = {
  name: string;
  label: string;
  onInputChange: (value: string) => void;
  error?: string;
  type?: React.HTMLInputTypeAttribute;
};

export const TextInput = ({
  name,
  label,
  onInputChange,
  error,
  type,
}: TextInputProps) => {
  const [value, setValue] = useState('');

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const newValue = e.currentTarget.value;

    setValue(newValue);
    onInputChange(newValue);
  };

  return (
    <S.Wrapper>
      <S.Label htmlFor={name}>{label}</S.Label>
      <S.Input type={type ?? 'text'} onChange={handleChange} value={value} />
      {!!error && <S.Error>{error}</S.Error>}
    </S.Wrapper>
  );
};
