import { AppContainer } from 'components/AppContainer';
import { CrateSimpleFlightForm } from 'components/CrateSimpleFlightForm';

export const CreateFlightPage = () => {
  return (
    <AppContainer>
      <CrateSimpleFlightForm />
    </AppContainer>
  );
};
