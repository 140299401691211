import styled from 'styled-components';
import * as TextInputStyles from 'components/TextInput';

export const Form = styled.form`
  display: flex;
  flex-direction: column;

  ${TextInputStyles.Wrapper} {
    margin-bottom: ${({ theme }) => theme.spacings.small};
  }
`;
