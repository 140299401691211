import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const Label = styled.label``;

export const Input = styled.input`
  ${({ theme }) => css`
    padding: ${theme.spacings.xxsmall};
    font-size: ${theme.font.sizes.large};
  `}
`;

export const Error = styled.span`
  ${({ theme }) => css`
    color: red;
    font-size: ${theme.font.sizes.small};
  `}
`;
