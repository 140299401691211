import { Footer } from 'components/Footer';
import { Nav } from 'components/Nav';
import { Title } from 'components/Title';
import * as S from './AppContainer.styles';

type AppContainerProps = {
  title?: string;
  children?: React.ReactNode;
};

export const AppContainer = ({ title, children }: AppContainerProps) => {
  return (
    <S.Wrapper>
      <div>
        <Nav />
        <S.Main>
          {title && <Title>{title}</Title>}
          {children}
        </S.Main>
      </div>
      <Footer />
    </S.Wrapper>
  );
};
