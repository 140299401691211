import styled, { css } from 'styled-components';

export const Wrapper = styled.footer`
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  ${({ theme }) => css`
    margin-top: ${theme.spacings.medium};
    margin-bottom: ${theme.spacings.medium};
    border-top: 1px ${theme.colors.black} solid;
    padding-top: ${theme.spacings.medium};
    width: 50%;
    height: 10rem;
  `}
`;

export const FooterList = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${({ theme }) => css`
    margin-top: ${theme.spacings.large};
  `}
`;

type FooterItemProps = {
  clickable?: boolean;
};

export const FooterItem = styled.a<FooterItemProps>``;

export const Copyright = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.gray};
    font-size: ${theme.font.sizes.xsmall};
    margin-top: ${theme.spacings.large};
    margin-bottom: ${theme.spacings.medium};
    text-align: center;
  `}
`;
