import { backendApi } from 'api/backendApi';
import { userStore } from 'contexts/userContext';
import { Link, useNavigate } from 'react-router-dom';
import { useStore } from 'zustand';
import * as S from './Nav.styles';

export const Nav = () => {
  const navigate = useNavigate();
  const { user, updateUser } = useStore(userStore);

  const handleLogout = async () => {
    const response = await backendApi.post('/api/v1/user/logout');
    if (response.status !== 200) {
      return;
    }

    await updateUser();

    navigate('/');
  };

  if (!user) {
    return (
      <S.Wrapper>
        <S.NavItems>
          <S.NavItem>
            <Link to="/">Home</Link>
          </S.NavItem>
          <S.NavItem>
            <Link to="/signup">SignUp</Link>
          </S.NavItem>
          <S.NavItem>
            <Link to="/login">Login</Link>
          </S.NavItem>
        </S.NavItems>
      </S.Wrapper>
    );
  }

  return (
    <S.Wrapper>
      <S.NavItems>
        <S.NavItem>
          <Link to="/">Home</Link>
        </S.NavItem>
        <S.NavItem>
          <Link to="/current-flight">Current Flight</Link>
        </S.NavItem>
        <S.NavItem>
          <Link to="/flights">My Flights</Link>
        </S.NavItem>
        <S.NavItem>
          <Link to="/my-aircraft">My Aircraft</Link>
        </S.NavItem>
        <S.NavItem>
          <Link to="/create-flight">Create Flight</Link>
        </S.NavItem>
        <S.NavItem>
          <Link to="/buy-aircraft">Buy Aircraft</Link>
        </S.NavItem>
        <S.NavItem>
          <Link to="/map">Map</Link>
        </S.NavItem>
      </S.NavItems>
      <S.NavItems>
        <S.NavItemWithoutClick>${user.balance}</S.NavItemWithoutClick>
        <S.NavItem onClick={handleLogout}>Logout</S.NavItem>
      </S.NavItems>
    </S.Wrapper>
  );
};
