import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
`;

export const Main = styled.main`
  ${({ theme }) => css`
    padding: 0 ${theme.spacings.xxlarge};
  `}
`;
