import { ButtonHTMLAttributes } from 'react';
import * as S from './Button.styles';

type ButtonTypes = ButtonHTMLAttributes<HTMLButtonElement>;

type ButtonProps = {
  children: React.ReactNode;
} & ButtonTypes;

export const Button = ({ children, ...props }: ButtonProps) => {
  return <S.Button {...props}>{children}</S.Button>;
};
