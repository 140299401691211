import * as S from './Table.styles';

export type TableProps<ItemsType> = {
  itemId: keyof ItemsType;
  headers: Record<keyof ItemsType, string>;
  items: ItemsType[];
  onRowClick?: (itemId: number | string) => void;
};

export const Table = <T extends Record<string, string | number | JSX.Element>>({
  itemId,
  headers,
  items,
  onRowClick,
}: TableProps<T>) => {
  return (
    <S.Table>
      <thead>
        <S.TableHeaderRow>
          {Object.values<string>(headers).map((header) => (
            <S.TableHeader key={header}>{header}</S.TableHeader>
          ))}
        </S.TableHeaderRow>
      </thead>
      <tbody>
        {items.map((item) => (
          <S.TableRow
            key={item[itemId] as string}
            clickable={!!onRowClick}
            onClick={() => onRowClick?.(item[itemId] as string)}
          >
            {Object.keys(headers).map((key) => (
              <S.TableValue key={key}>{item[key]}</S.TableValue>
            ))}
          </S.TableRow>
        ))}
      </tbody>
    </S.Table>
  );
};
