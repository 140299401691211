import {
  GetAircraftResponseV1,
  GetPayloadResponseV1,
} from '@backend/responses';
import {
  createFlightApi,
  getAircraftApi,
  getPayloadApi,
  loadAircraftApi,
  refuelAircraftApi,
  unloadAircraftApi,
} from 'api/backendApi';
import { Button } from 'components/Button';
import { Detail } from 'components/Detail';
import * as FormStyles from 'components/Form';
import { PaginatedTable } from 'components/PaginatedTable';
import { Table } from 'components/Table';
import { TextInput } from 'components/TextInput';
import { Title } from 'components/Title';
import { Decimal } from 'decimal.js';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as S from './AircraftDetail.styles';

type AircraftDetailProps = {
  aircraftId: string;
};

const payloadTableHeader = {
  id: 'Id',
  isPassenger: 'Is Passenger',
  weightInPounds: 'Weight',
  payment: 'Payment',
  action: 'Action',
};

const aircraftPayloadHeader = {
  id: 'Id',
  destination: 'Destination',
  weight: 'Weight',
  payment: 'Payment',
  action: 'Action',
};

type GetPaymentArg = {
  payload: {
    payment: string;
    isPassenger: boolean;
    weightInPounds: number;
  };
}[];

export const getPayloadSummary = (aircraftPayload: GetPaymentArg) => {
  const payment = aircraftPayload.reduce(
    (prev, next) => prev.add(next.payload.payment),
    new Decimal(0),
  );
  const payloadWeightInPounds = aircraftPayload.reduce(
    (prev, next) => prev + next.payload.weightInPounds,
    0,
  );
  const passengers = aircraftPayload.reduce(
    (prev, next) => prev + Number(next.payload.isPassenger),
    0,
  );

  return {
    payment,
    passengers,
    payloadWeightInPounds,
  };
};

export const AircraftDetail = ({ aircraftId }: AircraftDetailProps) => {
  const navigate = useNavigate();
  const [aircraft, setAircraft] = useState<GetAircraftResponseV1>();
  const [refuelQuantity, setRefuelQuantity] = useState(0);
  const [destination, setDestination] = useState('');
  const [payloads, setPayloads] = useState<GetPayloadResponseV1[]>([]);
  const [payloadPage, setPayloadPage] = useState(1);
  const [payloadTotalPages, setSayloadTotalPages] = useState(0);

  const fetchAircraft = async () => {
    const response = await getAircraftApi(aircraftId);

    if (!response.isOk) {
      toast.warning('Unable to fetch aircraft!');
      navigate('/my-aircraft');
      return;
    }

    setAircraft(response.data);
  };

  const fetchPayloads = async () => {
    if (!aircraft) {
      return;
    }
    const response = await getPayloadApi({
      departure: aircraft.currentAirport.icao,
      status: 'Available',
      page: payloadPage,
      pageSize: 10,
    });

    if (!response.isOk) {
      toast.error("Unable to fetch airport's payload");
      return;
    }

    setPayloads(response.data.items);
    setSayloadTotalPages(response.data._meta.totalPages);
  };

  useEffect(() => {
    fetchAircraft();
  }, []);

  useEffect(() => {
    fetchPayloads();
  }, [aircraft]);

  if (!aircraft) {
    return null;
  }

  const fuelPrice = new Decimal(aircraft.currentAirport.fuelPricePerUSGallon);
  const refuelPrice = fuelPrice.times(refuelQuantity);

  const handleRefuel: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    const result = await refuelAircraftApi(aircraftId, {
      quantity: refuelQuantity,
    });
    if (!result.isOk) {
      toast.error(result.webError.message);
      return;
    }
    toast.success('Aircraft refueled!');
    fetchAircraft();
  };

  const handleCreateFlight: React.FormEventHandler<HTMLFormElement> = async (
    e,
  ) => {
    e.preventDefault();

    const result = await createFlightApi(aircraftId, { destination });
    if (!result.isOk) {
      toast.error(result.webError.message);
      return;
    }
    toast.success('Flight created!');
    navigate('/current-flight');
  };

  const handleLoadAircraft = async (payloadId: string) => {
    const response = await loadAircraftApi(aircraftId, {
      payloadIds: [payloadId],
    });
    if (!response.isOk) {
      toast.error('Unable to load aircraft with payload');
      return;
    }

    toast.success('Aircraft loaded with payload!');
    fetchPayloads();
    fetchAircraft();
  };

  const handleUnloadAircraft = async (payloadId: string) => {
    const response = await unloadAircraftApi(aircraftId, {
      payloadIds: [payloadId],
    });
    if (!response.isOk) {
      toast.error('Unable to unload payload');
      return;
    }

    toast.success('Payload removed from your aircraft!');
    fetchPayloads();
    fetchAircraft();
  };

  const formatPayload = (payload: GetPayloadResponseV1) => {
    return {
      id: payload.id,
      isPassenger: payload.isPassenger ? '✔' : 'X',
      weightInPounds: `${payload.weightInPounds} lb`,
      payment: `$${payload.payment}`,
      action: (
        <Button onClick={() => handleLoadAircraft(payload.id)}>Load</Button>
      ),
    };
  };

  const { passengers, payloadWeightInPounds } = getPayloadSummary(
    aircraft.aircraftPayload,
  );

  const formatAircraftPayload = (
    aircraftPayloads: GetAircraftResponseV1['aircraftPayload'],
  ) => {
    return aircraftPayloads.map((aircraftPayload) => ({
      id: aircraftPayload.payload.id,
      destination: aircraftPayload.payload.destination.icao,
      weight: aircraftPayload.payload.weightInPounds,
      payment: `$${aircraftPayload.payload.payment}`,
      action: (
        <Button
          onClick={() => handleUnloadAircraft(aircraftPayload.payload.id)}
        >
          Unload
        </Button>
      ),
    }));
  };

  return (
    <S.Wrapper>
      <S.HeaderInformation>
        <S.HeaderAircraft>
          <S.AircraftDetails>
            <Title>
              {aircraft.register} ({aircraft.aircraftModel.typeDesignator})
            </Title>
            <Detail label="Airport" content={aircraft.currentAirport.icao} />
            <Detail
              label="Fuel Capacity"
              content={
                aircraft.aircraftModel.maxFuelInUSGallons.toLocaleString() +
                ' gallons'
              }
            />
            <Detail
              label="Fuel"
              content={aircraft.fuelInUSGallons.toLocaleString() + ' gallons'}
            />
            <Detail label="Passengers" content={passengers} />
            <Detail
              label="Payload Weight"
              content={`${payloadWeightInPounds} lb`}
            />
          </S.AircraftDetails>
          <Title>Current Payload</Title>
          <Table
            itemId="id"
            headers={aircraftPayloadHeader}
            items={formatAircraftPayload(aircraft.aircraftPayload)}
          />
        </S.HeaderAircraft>

        <S.HeaderAircraft>
          <S.RefuelWrapper>
            <Title>Refuel</Title>
            <Detail
              label="Fuel Price"
              content={'$' + fuelPrice.toNumber().toLocaleString()}
            />
            <FormStyles.Form onSubmit={handleRefuel}>
              <TextInput
                type="number"
                name="refuelQuantity"
                label="Refuel Quantity (in US gallons)"
                onInputChange={(v) => setRefuelQuantity(parseInt(v, 10))}
              />
              <Detail
                label="Price"
                content={'$' + refuelPrice.toNumber().toLocaleString()}
              />
              <Button>Refuel</Button>
            </FormStyles.Form>
          </S.RefuelWrapper>

          <S.CreateFlightWrapper>
            <Title>Create Flight</Title>
            <FormStyles.Form onSubmit={handleCreateFlight}>
              <TextInput
                type="string"
                name="refuelQuantity"
                label="Destination"
                onInputChange={setDestination}
              />
              <Button>Create Flight</Button>
            </FormStyles.Form>
          </S.CreateFlightWrapper>
        </S.HeaderAircraft>
      </S.HeaderInformation>

      <S.PayloadAvailableWrapper>
        <Title>Available payload at the current airport</Title>
        <PaginatedTable
          page={payloadPage}
          totalPages={payloadTotalPages}
          onNext={() => setPayloadPage((v) => v + 1)}
          onPrev={() => setPayloadPage((v) => v - 1)}
          items={payloads.map(formatPayload)}
          itemId="id"
          headers={payloadTableHeader}
        />
      </S.PayloadAvailableWrapper>
    </S.Wrapper>
  );
};
