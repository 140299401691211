import { backendApi } from 'api/backendApi';
import { Button } from 'components/Button';
import * as FormStyles from 'components/Form';
import { TextInput } from 'components/TextInput';
import { Title } from 'components/Title';
import { FormEventHandler, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as S from './CrateSimpleFlightForm.styles';

export const CrateSimpleFlightForm = () => {
  const navigate = useNavigate();
  const [departure, setDeparture] = useState('');
  const [destination, setDestination] = useState('');
  const [aircraftModel, setAircraftModel] = useState('');

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    const response = await backendApi.post('/api/v1/flight', {
      aircraftModelTypeDesignator: aircraftModel,
      departure,
      destination,
    });

    if (response.status !== 201) {
      toast.error(response.data.message);
      return;
    }

    navigate('/current-flight');
  };

  return (
    <S.Wrapper>
      <FormStyles.Form onSubmit={handleSubmit}>
        <Title>Create Flight</Title>
        <TextInput
          label="Aircraft Model"
          name="aircraftModel"
          onInputChange={setAircraftModel}
        />
        <TextInput
          label="Departure"
          name="departure"
          onInputChange={setDeparture}
        />
        <TextInput
          label="Destination"
          name="destination"
          onInputChange={setDestination}
        />
        <Button>Create Flight</Button>
      </FormStyles.Form>
    </S.Wrapper>
  );
};
