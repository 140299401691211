import { AppContainer } from 'components/AppContainer';
import { FlightDetail } from 'components/FlightDetail';
import { useNavigate, useParams } from 'react-router-dom';

export const FlightPage = () => {
  const navigate = useNavigate();
  const { flightId } = useParams();
  const flightIdNumber = Number(flightId);

  if (isNaN(flightIdNumber)) {
    navigate('/flights');
    return null;
  }

  return (
    <AppContainer title={`Flight # ${flightIdNumber}`}>
      <FlightDetail flightId={flightIdNumber} />
    </AppContainer>
  );
};
