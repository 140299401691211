import { Table, TableProps } from 'components/Table';
import { Button } from 'components/Button';
import * as S from './PaginatedTable.styles';

type PaginatedTableProps<T> = {
  page: number;
  totalPages: number;
  onPrev: () => void;
  onNext: () => void;
} & TableProps<T>;

export const PaginatedTable = <
  T extends Record<string, string | number | JSX.Element>,
>({
  page,
  totalPages,
  onPrev,
  onNext,
  ...props
}: PaginatedTableProps<T>) => {
  return (
    <S.Wrapper>
      <Table {...props} />
      <S.PageWrapper>
        <Button onClick={onPrev} disabled={page === 1}>
          Prev
        </Button>
        <span>Page: {page}</span>
        <Button onClick={onNext} disabled={page === totalPages}>
          Next
        </Button>
      </S.PageWrapper>
    </S.Wrapper>
  );
};
