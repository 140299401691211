import { AppContainer } from 'components/AppContainer';
import { BuyAircraftDetail } from 'components/BuyAircraftDetail';
import { useNavigate, useParams } from 'react-router-dom';

export const BuyAircraftDetailPage = () => {
  const { aircraftModelId } = useParams();
  const navigate = useNavigate();
  const aircraftModelIdNumber = Number(aircraftModelId);

  if (!aircraftModelId || isNaN(aircraftModelIdNumber)) {
    navigate('/');
    return null;
  }

  return (
    <AppContainer title="Aircraft Model Details">
      <BuyAircraftDetail aircraftModelId={aircraftModelIdNumber} />
    </AppContainer>
  );
};
