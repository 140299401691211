import type { GetAirportsV1Schema } from '@backend/domain/airport/routes/v1/handlers/get-airports';
import type { GetTrackV1Response } from '@backend/domain/flight/routes/track/v1/handlers/get';
import {
  BuyAircraftPayloadV1,
  CreateFlightPayloadV1,
  GetAirportQueryV1,
  GetPayloadParamsV1,
  LoadAircraftPayloadV1,
  RefuelAircraftPayloadV1,
  UnloadAircraftPayloadV1,
} from '@backend/requests';
import {
  BuyAircraftResponseV1,
  GetAircraftModelResponseV1,
  GetAircraftResponseV1,
  GetPayloadResponseV1,
  LoadAircraftResponseV1,
  PaginatedResponse,
  RefuelAircraftResponseV1,
  UnloadAircraftResponseV1,
  airportSchema,
} from '@backend/responses';
import axios, { AxiosResponse } from 'axios';
import { StatusCodes } from 'http-status-codes';
import { Result, WebError } from 'types';
import { z } from 'zod';

type BigIntToString<T> = {
  [K in keyof T]: T[K] extends bigint
    ? string
    : T[K] extends object
    ? BigIntToString<T[K]>
    : T[K];
};

export const backendApi = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  validateStatus: () => true,
  withCredentials: true,
});

export const refuelAircraftApi = async (
  aircraftId: number | string,
  { quantity }: RefuelAircraftPayloadV1,
): Promise<Result<RefuelAircraftResponseV1>> => {
  const response = await backendApi.post<RefuelAircraftResponseV1 | WebError>(
    `/api/v1/aircraft/${aircraftId}/refuel`,
    { quantity },
  );

  return parseApiResponse(response, StatusCodes.OK);
};

export const createFlightApi = async (
  aircraftId: number | string,
  payload: CreateFlightPayloadV1,
): Promise<Result<RefuelAircraftResponseV1>> => {
  const response = await backendApi.post<RefuelAircraftResponseV1 | WebError>(
    `/api/v1/aircraft/${aircraftId}/flight`,
    payload,
  );

  return parseApiResponse(response, StatusCodes.CREATED);
};

export const getAircraftModelListApi = async (
  page: number,
): Promise<Result<PaginatedResponse<GetAircraftModelResponseV1>>> => {
  const response = await backendApi.get<
    PaginatedResponse<GetAircraftModelResponseV1> | WebError
  >(`/api/v1/aircraft-model?page=${page}`);

  return parseApiResponse(response);
};

export const getAircraftModel = async (
  aircraftModelId: number | string,
): Promise<Result<GetAircraftModelResponseV1>> => {
  const response = await backendApi.get<GetAircraftModelResponseV1 | WebError>(
    `/api/v1/aircraft-model/${aircraftModelId}`,
  );

  return parseApiResponse(response);
};

export const buyAircraftApi = async (
  payload: BuyAircraftPayloadV1,
): Promise<Result<BuyAircraftResponseV1>> => {
  const response = await backendApi.post<BuyAircraftResponseV1 | WebError>(
    '/api/v1/aircraft/buy',
    payload,
  );

  return parseApiResponse(response, StatusCodes.CREATED);
};

export const getAircraftApi = async (
  aircraftId: number | string,
): Promise<Result<GetAircraftResponseV1>> => {
  const response = await backendApi.get<GetAircraftResponseV1>(
    `/api/v1/aircraft/${aircraftId}`,
  );

  return parseApiResponse(response);
};

export const loadAircraftApi = async (
  aircraftId: number | string,
  payload: BigIntToString<LoadAircraftPayloadV1>,
): Promise<Result<LoadAircraftResponseV1>> => {
  const response = await backendApi.post<LoadAircraftResponseV1>(
    `/api/v1/aircraft/${aircraftId}/load`,
    payload,
  );

  return parseApiResponse(response);
};

export const unloadAircraftApi = async (
  aircraftId: string,
  payload: BigIntToString<UnloadAircraftPayloadV1>,
): Promise<Result<UnloadAircraftResponseV1>> => {
  const response = await backendApi.post<UnloadAircraftResponseV1>(
    `/api/v1/aircraft/${aircraftId}/unload`,
    payload,
  );

  return parseApiResponse(response);
};

export const getPayloadApi = async (
  params: z.input<typeof GetPayloadParamsV1>,
): Promise<Result<PaginatedResponse<GetPayloadResponseV1>>> => {
  const response = await backendApi.get<
    PaginatedResponse<GetPayloadResponseV1>
  >('/api/v1/payload', { params });

  return parseApiResponse(response);
};

export const getAirportsApi = async (
  params: z.input<typeof GetAirportQueryV1>,
) => {
  const response = await backendApi.get<GetAirportsV1Schema>(
    '/api/v1/airports',
    { params },
  );
  return parseApiResponse(response);
};

export const getAirportByIdApi = async (airportId: string) => {
  const response = await backendApi.get<z.infer<typeof airportSchema>>(
    `/api/v1/airport/${airportId}`,
  );
  return parseApiResponse(response);
};

export const getFlightTracksApi = async () => {
  const response = await backendApi.get<GetTrackV1Response>('/api/v1/track');
  return parseApiResponse(response);
};

const parseApiResponse = <T>(
  response: AxiosResponse<T | WebError>,
  successStatusCode: StatusCodes = StatusCodes.OK,
): Result<T> => {
  if (response.status !== successStatusCode) {
    return {
      isOk: false,
      webError: response.data as WebError,
    };
  }

  return {
    isOk: true,
    data: response.data as T,
  };
};
