import * as S from './Footer.styles';

const currentYear = new Date().getFullYear();

export const Footer = () => {
  return (
    <S.Wrapper>
      <S.FooterList>
        <S.FooterItem href="/clients/installer.msi">
          Download client
        </S.FooterItem>
        <S.Copyright>
          Rafael Coelho {currentYear} © All rights reserved
        </S.Copyright>
      </S.FooterList>
    </S.Wrapper>
  );
};
