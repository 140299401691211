import styled, { css } from 'styled-components';
import * as DetailStyles from 'components/Detail';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 40%;

    ${DetailStyles.Wrapper} {
      margin-top: ${theme.spacings.xsmall};
    }
  `}
`;

export const FormWrapper = styled.div`
  ${({ theme }) => css`
    width: 40rem;
    margin-top: ${theme.spacings.medium};
    padding: ${theme.spacings.xlarge} ${theme.spacings.large};
    box-shadow: ${theme.boxShadow.large};
    border-radius: ${theme.border.radius};
  `}
`;
